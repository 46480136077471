
.App {
  text-align: center;
}
.App-header {
  background-color: #222;
  color: white;
  font-size: 1.25rem;
  margin: 0;
  padding: 1rem;
}
	